<template>
  <div>
    <div class="vx-row">
      <feather-icon
        v-if="EnglishLan"
        @click="back()"
        icon="ChevronLeftIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <feather-icon
        v-else
        @click="back()"
        icon="ChevronRightIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <p class="text-xl">
        {{ $t("SurgeryDetails") }}
      </p>
    </div>
    <div id="surgeryProfile" class="w-full">
      <div class="vx-row scroll">
        <table class="w-full mt-5 bg-white shadow-md">
          <tr class="h-32 p-5">
            <th class="text-2xl font-bold text-center">
              {{ Model.SurgeryName }}
            </th>
            <th class="text-2xl font-bold text-center">
              <p v-if="Model.SurgeryDate">
                {{ Model.SurgeryDate.split("T")[0] }}
              </p>
              <p v-else>{{ $t("Date") + " " }}{{ $t("NotSetYet") }}</p>
            </th>
            <th class="text-2xl font-bold text-center justify-between">
              <div
                class="vx-row"
                v-if="
                  Model.DoctorHospitalSurgerys &&
                  Model.DoctorHospitalSurgerys[0].DoctorID
                "
              >
              <imageLazy
                      :imageStyle="{ 'border-radius': '20px','width': '70px','height':'70px'}"
                      :imageClass="'rounded-full'"
                      :src="baseURL + Model.DoctorHospitalSurgerys[0].DoctorImage"
                      placeHolderType="doctor"
                    />
                
                <p class="mt-5 vx-col lg:w-2/3">
                  Dr.{{ Model.DoctorHospitalSurgerys[0].DoctorName }}
                </p>

                <a
                  style="border-radius: 10px; width: 50px; height: 40px"
                  class="p-3 mt-5 text-sm text-white bg-primary"
                  :href="baseURL + Model.DoctorHospitalSurgerys[0].CVPath"
                  target="_blank"
                >
                  C.V.
                </a>
              </div>
              <p v-else>{{ $t("Doctor") + " " }} {{ $t("NotSetYet") }}</p>
            </th>
          </tr>
        </table>

        <div class="vx-row mt-5 mb-4 w-full">
          <div class="vx-col">
          
                    <imageLazy
                      :imageStyle="{ 'border-radius': '20px' ,'width':'130px','height':'130px'}"
                      :imageClass="'doctorImage'"
                      :src="baseURL + Model.HospitalImage"
                      placeHolderType="hospital"
                    />
          </div>
          <div class="vx-col w-full lg:w-2/3 FontHead">
            <h2 class="m-5 w-full">
              <p>{{ Model.HospitalName }}</p>
            </h2>
            <star-rating
              class="m-3"
              :max-rating="5"
              :star-size="30"
              v-model="Model.HospitalRate"
              :read-only="true"
              :show-rating="false"
            ></star-rating>
            <!-- <u class="text-primary text-xl"> {{ $t("HospitalInfo") }}</u> -->
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col lg:w-2/3 mb-3">
          <div class="vx-row mt-6 w-full">
            <h3>
              <p>{{ $t("SurgeryTreatmentIncludes") }}</p>
            </h3>
          </div>
          <div
            class="vx-row ml-10"
            v-for="item in Model.SurgeryIncludedItems"
            :key="item.ID"
          >
            <p class="circle primaryBackColor mt-1 mr-2"></p>
            <h5>
              <p>{{ item.Name }}</p>
            </h5>
          </div>

          <div class="vx-row mt-6">
            <h3>
              <p>{{ $t("SurgeryTreatmentNotIncludes") }}</p>
            </h3>
          </div>

          <div
            class="vx-row xl:w-1/2 sm:w-1/4 ml-10"
            v-for="item in Model.SurgeryExcludedItems"
            :key="item.ID"
          >
            <p class="circle primaryBackColor mt-1 mr-2"></p>
            <h5>
              <p>{{ item.Name }}</p>
            </h5>
          </div>
        </div>

        <div class="vx-col lg:w-1/3">
          <div>
            <p style="color: Green; font-size: 1.2rem">
              {{ $t("deposit") }} : {{ Model.ProcessingFees + Model.VAT }} USD
            </p>
            <p style="color: red; font-size: 1.2rem" class="mt-2">
              <span v-if="Model.PaymentStatusID != 5">
                {{ $t("RemainingSurgeryAmount") }}</span
              >
              <span v-else>{{ $t("SurgeryPrice") }}</span> :
              {{ Model.Price }} USD
            </p>
            <p
              style="font-size: 1.2rem"
              class="mt-2"
              v-if="Model.ExtraServiceTotalPrice"
            >
              {{ $t("ExtraServiceTotalPrice") }} :
              {{ Model.ExtraServiceTotalPrice }} USD
            </p>
            <p
              style="font-size: 1.2rem"
              class="mt-2"
              v-if="Model.ExtraServiceUnpayedPrice"
            >
              {{ $t("UnPayedExtraService") }} :
              {{ Model.ExtraServiceUnpayedPrice }} USD
            </p>
            <p style="font-size: 1.2rem" class="mt-2">
              {{ $t("PaymentStatus") }} :
              <span v-if="Model.PaymentStatusID == 1">
                {{ $t("PendingProcessFees") }}
              </span>
              <span v-else> {{ Model.PaymentStatusName }} </span>
              -
              <span v-if="Model.PaymentStatusID == 7">{{
                $t("PendingRmainSurgeryAmount")
              }}</span>
            </p>
            <p style="font-size: 1.2rem" class="mt-2">
              {{ $t("Status") }} : {{ Model.StatusName }}
            </p>
          </div>
          <p
            v-if="
              Model.SurgeryDate && (Model.StatusID == 1 || Model.StatusID == 2)
            "
          >
            If you have issue with scheduled date, you can
            <a href="mailto:" style="color: #008abe"> contact us </a> to resolve
            it.
          </p>

          <vs-button
            v-if="Model.StatusID != 3 && Model.StatusID != 5"
            class="w-full mt-5"
            type="filled"
            color="red"
            @click="showConfirmDialog = true"
            >{{ $t("CancelSurgeryRequest") }}</vs-button
          >
          <div v-if="Model.StatusID != 3 && Model.StatusID != 5">
            <vs-button
              class="w-full mt-5"
              type="filled"
              @click="
                ShowPatientSurgeryReport = true;
                GetSurgeryDocuments();
              "
              >{{ $t("MedicalDocumentation") }}</vs-button
            >
            <vs-button
              class="w-full mt-5"
              type="filled"
              @click="showTravelarrangements = true"
              >{{ $t("TravelArrangements") }}</vs-button
            >
            <vs-button
              class="w-full mt-5"
              type="filled"
              @click="showExtras = true"
              >{{ $t("ExtraServices") }}</vs-button
            >
            <vs-button
              v-if="Model.PaymentStatusID == 1"
              class="w-full mt-5"
              type="filled"
              @click="PayProcessNow"
              >{{ $t("PayProcessingFees") }}</vs-button
            >

            <vs-button
              v-if="
                Model.ExtraServiceUnpayedPrice &&
                Model.ExtraServiceUnpayedPrice > 0
              "
              class="w-full mt-5"
              type="filled"
              @click="PayExtraServices"
              >{{ $t("PayExtraServices") }}</vs-button
            >

            <vs-button
              v-if="
                Model.IsAllowHospitalBankTransfare &&
                Model.PaymentStatusID == 7 &&
                Model.StatusID > 1
              "
              class="w-full mt-5"
              color="primary"
              @click="ShowBankDetails = true"
              type="filled"
              >{{ $t("HospitalBankTransferDetails") }}</vs-button
            >

            <div
              v-if="
                !Model.IsAllowHospitalBankTransfare &&
                Model.PaymentStatusID == 7 &&
                Model.StatusID > 1
              "
            >
              <vs-button
                class="w-full mt-5"
                type="filled"
                v-if="Model.IsBeFord"
                @click="PayNow"
                >{{ $t("PayRemainingSurgeryAmount") }}</vs-button
              >

              <vs-button
                v-else
                @click="ShowBankDetails = true"
                class="w-full mt-5"
                color="primary"
                type="filled"
                >{{ $t("DocliniaBankTransferDetails") }}</vs-button
              >
            </div>
            <p v-if="Model.StatusID == 1 && Model.PaymentStatusID > 1">
              {{ $t("SurgeryPayRemainNote") }}
            </p>
            <h4
              class="mt-5 warning"
              v-if="Model.StatusID == 1 && Model.PaymentStatusID == 1"
            >
              {{ $t("SurgeryProcessingNote") }}
            </h4>
            <!-- <vs-button class="w-full mt-5" color="primary" type="filled">{{
          $t("Payments")
        }}</vs-button> -->
          </div>
        </div>
      </div>

      <vs-popup fullscreen title="" :active.sync="showExtras">
        <Extras v-if="showExtras" @closePop="CloseExtraPop"></Extras>
      </vs-popup>

      <vs-popup title="" :active.sync="showTravelarrangements">
        <Travelarrangements
          @closePop="showTravelarrangements = false"
          v-if="showTravelarrangements"
        >
        </Travelarrangements>
      </vs-popup>

      <vs-popup title="" :active.sync="ShowPatientSurgeryReport">
        <PatientSurgeryReport
          @closePop="ShowPatientSurgeryReport = false"
          v-if="ShowPatientSurgeryReport"
        ></PatientSurgeryReport>
      </vs-popup>

      <vs-popup title="" :active.sync="ShowPatientSurgeryReport">
        <PatientSurgeryReport
          @closePop="ShowPatientSurgeryReport = false"
          v-if="ShowPatientSurgeryReport"
        ></PatientSurgeryReport>
      </vs-popup>

      <vs-popup title="" :active.sync="ShowBankDetails">
        <BanKDetails
          @closePop="ShowBankDetails = false"
          v-if="ShowBankDetails"
          :Model="Model.BankingDetails"
        ></BanKDetails>
      </vs-popup>

      <vs-popup title="" :active.sync="showConfirmDialog">
        <confirmDialog
          @closePop="showConfirmDialog = false"
          @confirm="CancelSurgeryRequest"
          :showCancelControls="true"
          :modelCancel="modelCancel"
          v-if="showConfirmDialog"
          :message="$t('surgeryCancelConfirm')"
        ></confirmDialog>
      </vs-popup>
    </div>
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import Extras from "@/views/Patient/PatientSurgeries&Treatments/Extras.vue";
import Travelarrangements from "@/views/Patient/PatientSurgeries&Treatments/Travelarrangements.vue";
import BanKDetails from "@/views/Patient/PatientSurgeries&Treatments/BanKDetails.vue";
import { domain, mainSetting } from "@/gloabelConstant.js";
import StarRating from "vue-star-rating";
import point from "../../../components/point.vue";
import PatientSurgeryReport from "@/views/Patient/PatientSurgeryReport.vue";
import HospitalModule from "@/store/hospital/moduleHospital.js";
import confirmDialog from "@/components/confirmDialog.vue";
import moduleExtraService from "@/store/settings/extraService/moduleExtraService.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  data() {
    return {
      EnglishLan: false,
      showTravelarrangements: false,
      ShowBankDetails: false,
      showExtras: false,
      baseURL: domain,
      modelCancel: {},
      ShowPatientSurgeryReport: false,
      uploadUrl: domain + "api/Common/UploadFile",
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      Model: {},
      showConfirmDialog: false,
    };
  },
  components: {
    imageLazy,
    confirmDialog,
    Extras,
    // Travelarrangements,
    StarRating,
    point,
    Travelarrangements,
    BanKDetails,
    PatientSurgeryReport,
  },

  methods: {
    PayExtraServices() {
      this.Model.FrontUrl = window.location.origin
      this.$store
        .dispatch("ExtraServiceList/ExtraPaymentPrepair", this.Model)
        .then((res) => {
          window.location.href  = res.data.Data.transaction.url;
          // if (res.data.Data.amount && res.data.Data.amount > 0) {
          //   document.getElementById("amount").value = res.data.Data.amount;
          //   document.getElementById("customer_email").value =
          //     res.data.Data.customer_email;
          //   document.getElementById("signature").value =
          //     res.data.Data.signature;
          //   document.getElementById("merchant_reference").value =
          //     res.data.Data.merchant_reference;
          //   document.getElementById("paymentForm").submit();
          // }
        });
    },
    CloseExtraPop() {
      this.showExtras = false;
      this.getsurgeryDetail(this.Model.ID);
    },
    PayNow() {
      debugger;
      this.$vs.loading();
      this.Model.FrontUrl = window.location.origin
      this.$store
        .dispatch("patientList/SurgeryPaymentDatail", this.Model)
        .then((res) => {
           window.location.href  = res.data.Data.transaction.url;
          // this.$vs.loading.close();
          // document.getElementById("amount").value = res.data.Data.amount;
          // document.getElementById("customer_email").value =
          //   res.data.Data.customer_email;
          // document.getElementById("signature").value = res.data.Data.signature;
          // document.getElementById("merchant_reference").value =
          //   res.data.Data.merchant_reference;
          // document.getElementById("paymentForm").submit();
        });
    },
    PayProcessNow() {
      this.$vs.loading();
this.Model.FrontUrl = window.location.href
      this.$store
        .dispatch("patientList/ProcessingPaymentDatail", this.Model)
        .then((res) => {
          debugger
          this.$vs.loading.close();
          window.location.href  = res.data.Data.transaction.url;
          // document.getElementById("amount").value = res.data.Data.amount;
          // document.getElementById("customer_email").value =
          //   res.data.Data.customer_email;
          // document.getElementById("signature").value = res.data.Data.signature;
          // document.getElementById("merchant_reference").value =
          //   res.data.Data.merchant_reference;
          // document.getElementById("paymentForm").submit();
        });
    },
    back() {
      this.$router.go(-1);
    },
    CancelSurgeryRequest() {
      var reservedSurgery = {
        PatientReservationSurgeryID: this.Model.ID,
        StatusID: 3,
        CancellationReasons: this.modelCancel.CancellationReasons,
      };
      this.$store
        .dispatch(
          "HospitalList/ChangePatientReservationSurgeryStatus",
          reservedSurgery
        )
        .then((res) => {
          debugger;
          this.$vs.notify({
            title: this.$t("success"),
            text: res.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          this.showConfirmDialog = false;

          this.getsurgeryDetail(this.Model.ID);
        })
        .catch((err) => {
          debugger;
          this.$vs.notify({
            title: this.$t("Error"),
            text: err.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    getPatientReservationSurgery(id) {
      var model = {};
      model.PatientId = id;
      model.StatusID = 2; //confirmed
      this.$vs.loading();

      this.$store
        .dispatch("patientList/getPatientReservationSurgery", model)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    GetSurgeryDocuments() {
      this.$store.dispatch(
        "patientList/GetSurgeryDocuments",
        this.$route.params.ID
      );
    },
    getsurgeryDetail(ID) {
      this.$store
        .dispatch("patientList/GetPatientReservationSurgery", ID)
        .then((res) => {
          debugger;
          if (res.status == 200) {
            debugger;
            this.Model = res.data.Data;
            if (
              this.Model.HospitalRate == null ||
              this.Model.HospitalRate == undefined ||
              this.Model.HospitalRate == "NaN"
            ) {
              this.Model.HospitalRate = 0;
            }
          }
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;
    if (!moduleExtraService.isRegistered) {
      this.$store.registerModule("ExtraServiceList", moduleExtraService);
      moduleExtraService.isRegistered = true;
    }
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    if (!HospitalModule.isRegistered) {
      this.$store.registerModule("HospitalList", HospitalModule);
      HospitalModule.isRegistered = true;
    }

    const ID = this.$route.params.ID;
    const StatusID = this.$route.params.StatusID;

    this.getsurgeryDetail(ID);
    if (StatusID) {
      if (StatusID != 14) {
        window.showError(this.$t("Paymentfail"));
      }
    }
  },
};
</script>
<style >
.vs-table--not-data {
  display: none;
}
</style>
<style scoped>
#surgeryProfile th:last-child {
  border-top-right-radius: 0px;
}
#surgeryProfile th:first-child {
  border-top-left-radius: 0px;
}
</style>


<style>
#surgeryProfile .popup-example {
  width: 50%;
  height: 20%;
}
#surgeryProfile table {
  border-collapse: collapse;
  border-radius: 2em;
  overflow: hidden;
  border: 1px solid black;
}
#surgeryProfile th,
#surgeryProfile td {
  border: 1px solid #8d777729;
  border-collapse: collapse;
}

#surgeryProfile .background {
  color: rgb(255, 9, 9);
}
.scroll {
  overflow-x: auto !important;
}
</style>

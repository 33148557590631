<template>
    <div
    class="flex w-full bg-img vx-row no-gutter items-center justify-center "
      style="height:700px"
  >
    <div class="vx-col w-full md:w-1/2 lg:w-3/4 sm:m-0 m-4 mycard">
      <vx-card>
        <div slot="no-body">

          <div
            class="vx-row no-gutter justify-center items-center mycard"
            style="background: transparent radial-gradient(closest-side at 50% 50%, #004477 0%, #004477 100%) 0% 0% no-repeat padding-box ;
                    box-shadow: -3px 0px 22px #00000029;
                      margin: -13px;
                    border-radius: 31px;
                    opacity: 1; "
          >

            <div class="vx-col text-center align-center lg:block lg:w-3/4 sm:w-1/4 ">
            <div class="MyPopClose" >
                <feather-icon class="mt-5 " style="color:white" icon="XIcon" svgClasses="w-10 h-10 stroke-current" @click.stop="$emit('closePop')" />
            </div>
              <img
                src="@/assets/images/footer_logo.png"
                style="max-width:100px ;"
                alt="login"
                class="mx-auto mt-3"
              />
              <div class="mb-5 mt-5">
                <p
                  style="text-align: center;
                  font: normal normal 300 20px/21px Futura PT;
                       letter-spacing: 0px;
                        color: #FFFFFF;"
                >
                 {{message}}
                </p>
              </div>
           <div class="mb-5" v-if="showCancelControls">
          <vs-input
            class="w-full  mt-4"
            rows="5"
            v-model="modelCancel.CancellationReasons"
            name="CancellationReasons"
            :placeholder="$t('cancellationReason')"
          />
              </div>
                <div class="mb-5" v-if="showCancelControls">
                <p style="color: #FFFFFF; text-align: left; ">
                 {{$t("cancelSurgeryNote")}}
                </p>
              </div>
              <vs-button @click="$emit('confirm')" text-color="#004477" color="white"  class="mb-5">
                  {{$t("Confirm")}}
              </vs-button>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
export default {
    props:{
        message:{
            type:String,
            default:""
        },
        showCancelControls:{
          type:Boolean,
          default:false
        },
        modelCancel:
        {
            type:Object,
            default:{}
        }
    }
}
</script>


<style lang="scss">

.login-tabs-container {
  min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
  .background {
    color: rgb(255, 9, 9);
  }
}
.mycard .vx-card {
  background: unset;
  // padding: 0px !important;
}
</style>
